import _export from "../internals/export";
import _global3 from "../internals/global";
import _schedulersFix from "../internals/schedulers-fix";
var $ = _export;
var _global2 = _global3;
var setTimeout = _schedulersFix.setTimeout; // ie9- setTimeout additional parameters fix
// https://html.spec.whatwg.org/multipage/timers-and-user-prompts.html#dom-settimeout

$({
  global: true,
  bind: true,
  forced: _global2.setTimeout !== setTimeout
}, {
  setTimeout: setTimeout
});
export default {};