import _global3 from "../internals/global";
import _functionApply from "../internals/function-apply";
import _isCallable from "../internals/is-callable";
import _engineUserAgent from "../internals/engine-user-agent";
import _arraySlice from "../internals/array-slice";
import _validateArgumentsLength from "../internals/validate-arguments-length";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var _global2 = _global3;
var apply = _functionApply;
var isCallable = _isCallable;
var userAgent = _engineUserAgent;
var arraySlice = _arraySlice;
var validateArgumentsLength = _validateArgumentsLength;
var MSIE = /MSIE .\./.test(userAgent); // <- dirty ie9- check

var Function = _global2.Function;

var wrap = function (scheduler) {
  return MSIE ? function (handler, timeout
  /* , ...arguments */
  ) {
    var boundArgs = validateArgumentsLength(arguments.length, 1) > 2;
    var fn = isCallable(handler) ? handler : Function(handler);
    var args = boundArgs ? arraySlice(arguments, 2) : undefined;
    return scheduler(boundArgs ? function () {
      apply(fn, this || _global, args);
    } : fn, timeout);
  } : scheduler;
}; // ie9- setTimeout & setInterval additional parameters fix
// https://html.spec.whatwg.org/multipage/timers-and-user-prompts.html#timers


exports = {
  // `setTimeout` method
  // https://html.spec.whatwg.org/multipage/timers-and-user-prompts.html#dom-settimeout
  setTimeout: wrap(_global2.setTimeout),
  // `setInterval` method
  // https://html.spec.whatwg.org/multipage/timers-and-user-prompts.html#dom-setinterval
  setInterval: wrap(_global2.setInterval)
};
export default exports;