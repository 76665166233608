import _export from "../internals/export";
import _global3 from "../internals/global";
import _schedulersFix from "../internals/schedulers-fix";
var $ = _export;
var _global2 = _global3;
var setInterval = _schedulersFix.setInterval; // ie9- setInterval additional parameters fix
// https://html.spec.whatwg.org/multipage/timers-and-user-prompts.html#dom-setinterval

$({
  global: true,
  bind: true,
  forced: _global2.setInterval !== setInterval
}, {
  setInterval: setInterval
});
export default {};